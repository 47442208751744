* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: #181c1f;
}


main {

}

.maze-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*height: 40vh;*/
}


.button-row {
  display: flex;
  flex-direction: row;
 }

.maze-button {
  background-color: #3232ef;
  margin: 10px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

.maze {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.row {
  display: flex;
  flex-direction: row;
}

.cell {
  width: 40px;
  height: 40px;
  border: 1px solid white;
}

.cell.wall {
  background-color: black;
}

.cell.path {
  background-color: #d5d5d5;
}

.cell.start {
  background-color: green;
}

.cell.end {
  background-color: red;
}

.cell.visited {
  background-color: #94a6fa;
}

.cell.testing {
  background-color: #c3c3c3;
}


.cell.changing {
  background-color: #bebebe;
}


